<template>
  <TTView>
    <VRow>
      <VCol>
        <InvitationForm
          :entity="invitation"
          :companies="companies"
          @update:companyId="invitation.companyId = $event"
          @update:serviceName="invitation.serviceName = $event"
          @update:visibility="invitation.visibility = $event"
          @update:defaults="invitation.defaults = $event"
          @update:schema="invitation.schema = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_INVITATION } from '../../components/invitations/common';
import InvitationForm from '../../components/invitations/InvitationForm.vue';

export default {
  name: 'InvitationsCreate',

  components: {
    InvitationForm,
  },

  inject: ['Names'],

  data() {
    return {
      companies: [],
      invitation: { ...DEFAULT_INVITATION },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const [companiesResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
        ]);

        this.companies = companiesResponse.companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const { invitation } = this;
        delete invitation.id;
        const data = { invitation };
        const { id: invitationId } = await this.$di.api.Invitations.InvitationCreate(data);
        this.$router.push({
          name: this.Names.R_INVITATION,
          params: { invitationId },
        });
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
